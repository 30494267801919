<template>
  <div>
    <h3>Create Census Tract Audience</h3>
    <div class="sections">
      <div>
        <census-tract-form @upload="handleUpload" />
      </div>
    </div>
  </div>
</template>

<script>
import CensusTractForm from '../../audience/CensusTractForm';

export default {
  components: {
    CensusTractForm,
  },

  methods: {
    handleUpload() {
      this.$refs.requestTable.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.sections > div:not(:last-of-type) {
  margin-bottom: 1em;
}
</style>
